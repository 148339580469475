import { Toolbar } from "primereact/toolbar";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { HelpButton } from ".";
import "quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";

const TextEditor = ({
  name,
  value, // It should only be initial value
  title,
  subtitle,
  submitted,
  helpInformation,
  messageError,
  actionButtonDisabled,
  onInputChange,
  onSave,
  btnSaveProps = {},
}) => {
  const [quill, setQuill] = useState(null);
  const currentValue = useRef(value);

  /**
   * @todo delete after primereact upgrade
   */
  useEffect(() => {
    if (quill && currentValue.current !== value) {
      // quill.setContents(
      //   quill.clipboard.convert({
      //     html: value,
      //     text: "",
      //   })
      // );
      currentValue.current = value;
    }
  }, [value, quill]);

  const leftToolbarTemplate = () => {
    return (
      <>
        <h3>
          {title}
          <HelpButton message={helpInformation} />
        </h3>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-success"
          onClick={() => onSave(name)}
          disabled={actionButtonDisabled}
          {...btnSaveProps}
        />
      </>
    );
  };

  return (
    <>
      <Toolbar
        className="p-mb-4 p-toolbar"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      />

      <div className="p-field p-col-12">
        <label htmlFor="long_description">{subtitle}</label>

        <Editor
          style={{ height: "320px", fontSize: "18px" }}
          value={value}
          required
          onTextChange={(e) => {
            onInputChange(e, name);
          }}
          onLoad={(quill) => {
            setQuill(quill);
          }}
        />

        {submitted && !value && (
          <small className="p-invalid color-danger">{messageError}</small>
        )}
      </div>
    </>
  );
};

export default TextEditor;
