/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
type LoggerType = "LOG" | "INFO" | "WARN" | "ERROR";

type LoggerDef = (message?: any, ...optionalParams: any[]) => void;

function getColor(type: LoggerType): string {
  switch (type) {
    case "LOG":
      return "gray";
    case "INFO":
      return "teal";
    case "WARN":
      return "orange";
    case "ERROR":
      return "red";
  }
}

function getLogger(
  consoleLog: LoggerDef,
  context: LoggerType,
  show = true
): LoggerDef {
  if (!show) {
    return loggerNone;
  }
  const timestamp = function () {};
  timestamp.toString = function () {
    const date = new Date();
    return `${date} ${context}`;
  };
  return consoleLog.bind(
    console,
    "%c%s",
    `color: ${getColor(context)}`,
    timestamp
  );
}

function loggerNone(_message?: any, ..._optionalParams: any[]): void {}

/**
 * This allows logger to use as logger('example') or logger.log('example')
 * in the same variable
 */
export interface LoggerFunction {
  (message?: any, ...optionalParams: any[]): void;
  log: {
    (message?: any, ...optionalParams: any[]): void;
  };
  info: {
    (message?: any, ...optionalParams: any[]): void;
  };
  warn: {
    (message?: any, ...optionalParams: any[]): void;
  };
  error: {
    (message?: any, ...optionalParams: any[]): void;
  };
  get: {
    (type: LoggerType): LoggerDef;
  };
}

/**
 * This logger is enable only in dev mode
 * @returns Custom logger
 */
export const buildLogger: () => LoggerFunction = () => {
  const show = process.env.NODE_ENV !== "production";
  const print = getLogger(console.log, "LOG", show);
  (print as any).log = getLogger(console.log, "LOG", show);
  (print as any).info = getLogger(console.info, "INFO", show);
  (print as any).warn = getLogger(console.warn, "WARN", show);
  (print as any).error = getLogger(console.error, "ERROR", show);
  return print as LoggerFunction;
};

export const logger = buildLogger();
