import React from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const DeteleObjectDialog = (props) => {
  const deleteDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setDeleteDialog(false);
        }}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={props.deleteConcept}
      />
    </>
  );

  return (
    <>
      <Dialog
        visible={props.deleteDialog}
        style={{ width: "350px" }}
        header="Confirmar"
        modal
        footer={deleteDialogFooter}
        onHide={() => {
          props.setDeleteDialog(false);
        }}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {props.title ? (
            <span>{props.title}</span>
          ) : (
            props.concept && (
              <span>
                ¿Desea eliminar <b>{props.concept?.name}</b>?
              </span>
            )
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DeteleObjectDialog;
