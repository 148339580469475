import axios from "axios";
import * as urls from "../../api/urls";
import { createAction } from "redux-actions";

//export const setLoadingCurrentKnowledgeAction = createAction('SET_LOADING_CURRENT_KNOWLEDGE_ACTION');
export const setCurrentProcessDataAction = createAction(
  "SET_CURRENT_PROCESS_DATA_ACTION"
);
export const setCurrentProcessErrorAction = createAction(
  "SET_CURRENT_PROCESS_ERROR_ACTION"
);
export const setResetCurrentProcessAction = createAction(
  "SET_RESET_CURRENT_PROCESS_ACTION"
);
export const setProcessState = createAction("SET_PROCESS_STATE");
export const setIndicatorProcessState = createAction("SET_INDICATOR_PROCESS");
export const setToolProcessState = createAction("SET_TOOL_PROCESS");
export const setResourceProcessState = createAction("SET_RESOURCE_PROCESS");
export const setResultProcessState = createAction("SET_RESULT_PROCESS");

export const getProcessById =
  (processId, token, history, redirect = true) =>
  async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.get(
      urls.URL_BASE + urls.urlGetProcessById + processId + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(setProcessState(data));

      if (redirect) {
        history.push({
          pathname: "/admin/add-process",
          state: {
            process: data,
          },
        });
      }
    } else {
      throw new Error("Error reading process");
    }
  };

export const getXMLProcessById = async (processId, token, role) => {
  const stage_role = "/" + role;

  const response = await axios.get(
    urls.URL_BASE + urls.urlGetProcessById + processId + stage_role,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

export const updateProcessXML = async (processId, xml, token, role) => {
  const data = {
    process_id: processId,
    xml_body: xml,
  };

  const response = await axios.put(
    urls.URL_BASE + urls.urlUpdateProcessXML + role,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

export const updateProcess = async (process_id, process, token, role) => {
  const stage_role = "/" + role;
  const body = { ...process };

  const response = await axios.put(
    urls.URL_BASE + urls.urlUpdateProcess + process_id + stage_role,
    body,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

export const createProcess = async (process, token, role) => {
  const body = {
    name: process?.name,
    description: process?.description,
    model_id: process?.model_id,
    moment_model_id: process?.moment,
    type: "template",
  };

  const response = await axios.post(
    urls.URL_BASE + urls.urlCreateProcess + role,
    body,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

export const deleteProcess = async (process_id, token, role) => {
  const stage_role = "/" + role;

  const response = await axios.delete(
    urls.URL_BASE + urls.urlDeleteProcess + process_id + stage_role,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

// INDICATORS *************************************************************************************

export const createIndicatorProcess =
  (processData, token, arrayIndicators) => async (dispatch, getState) => {
    try {
      let dataCreate = {
        description: processData?.description,
        process_id: processData?.process_id,
        indicator_id: processData?.indicator_id,
      };

      const { data, status } = await axios.post(
        `${urls.URL_BASE}${urls.urlCreateIndicatorProcess}`,
        dataCreate,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (status === 200 || status === 201) {
        let datosObtenidos = [];

        arrayIndicators.map((indicador) => {
          data.map((indicadorProceso) => {
            if (indicador.id === indicadorProceso.indicator_id) {
              const indicator_info = {
                name: indicador.name,
                context: indicador.context,
                definition: indicador.definition,
                id: indicador.id,
              };
              console.log(indicador.definition);
              datosObtenidos.push(indicator_info);
            }
          });
        });
        dispatch(setIndicatorProcessState(datosObtenidos));
        return data;
      } else {
        throw new Error("Error associating process to indicator");
      }
    } catch (error) {
      throw error;
    }
  };

export const getIndicatorsByProcessId =
  (processId, token, arrayIndicators) => async (dispatch, getState) => {
    const { data, status } = await axios.get(
      `${urls.URL_BASE}${urls.urlGetIndicatorProcess}?process_id=${processId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      let datosObtenidos = [];

      arrayIndicators.map((indicador) => {
        data.map((indicadorProceso) => {
          if (indicador.id === indicadorProceso.indicator_id) {
            const indicator_info = {
              name: indicador.name,
              context: indicador.context,
              definition: indicador.definition,
              id: indicador.id,
            };
            console.log(indicador.definition);
            datosObtenidos.push(indicator_info);
          }
        });
      });

      let resultados = [];
      console.log(datosObtenidos);
      dispatch(setIndicatorProcessState(datosObtenidos));
    } else {
      throw new Error("Error associating process to indicator");
    }
  };

export const deleteProcessIndicator =
  (processId, indicatorId, token, arrayIndicators) =>
  async (dispatch, getState) => {
    const { data, status } = await axios.put(
      `${urls.URL_BASE}${urls.urlDeleteIndicatorProcess}?process_id=${processId}&indicator_id=${indicatorId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(getIndicatorsByProcessId(processId, token, arrayIndicators));
      return data;
    }
  };

/*
 * TOOL PROCESS
 */

export const createToolProcess = async (process, token, role) => {
  let body = {
    process_id: process?.process_id,
    toolbox_id: process?.toolbox_id,
  };

  const response = await axios.post(
    urls.URL_BASE + urls.urlCreateToolProcess + role,
    body,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response;
};

export const getToolsByProcessId =
  (processId, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.get(
      urls.URL_BASE + urls.urlListToolProcess + processId + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(setToolProcessState(data));
    } else {
      throw new Error("Error associating process to indicator");
    }
  };

export const deleteToolProcess =
  (process_id, tool_process_id, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.delete(
      urls.URL_BASE + urls.urlDeleteToolProcess + tool_process_id + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(getToolsByProcessId(process_id, token));
      return data;
    }
  };

/*
 * RESOURCE PROCESS
 */

export const getResourcesByProcessId =
  (processId, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.get(
      urls.URL_BASE + urls.urlListResourceProcess + processId + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(setResourceProcessState(data));
    } else {
      throw new Error("Error associating process to indicator");
    }
  };

export const createResourceProcess =
  (data, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;

    try {
      let body = {
        name: data?.name,
        description: data?.description,
        process_id: data?.process_id,
      };

      const { status } = await axios.post(
        urls.URL_BASE + urls.urlCreateResourceProcess + role,
        body,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (status === 200 || status === 201) {
        dispatch(getResourcesByProcessId(data?.process_id, token));
      } else {
        throw new Error("Error associating process to resource");
      }
    } catch (error) {
      throw error;
    }
  };

export const deleteResourceProcess =
  (processId, resource_id, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.delete(
      urls.URL_BASE + urls.urlDeleteResourceProcess + resource_id + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(getResourcesByProcessId(processId, token));
      return data;
    }
  };

/*
 * RESULT PROCESS
 */

export const getResultsByProcessId =
  (processId, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.get(
      urls.URL_BASE + urls.urlListResultProcess + processId + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(setResultProcessState(data));
    } else {
      throw new Error("Error associating process to indicator");
    }
  };

export const createResultProcess =
  (data, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;

    try {
      let body = {
        process_id: data?.process_id,
        long_description: data?.long_description,
      };

      const { data: response, status } = await axios.post(
        urls.URL_BASE + urls.urlCreateResultProcess + role,
        body,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (status === 200 || status === 201) {
        dispatch(getResultsByProcessId(data?.process_id, token));
        return response;
      } else {
        throw new Error("Error associating process to resource");
      }
    } catch (error) {
      throw error;
    }
  };

export const updateResultProcess =
  (processId, resultId, token, dataParameter) => async (dispatch, getState) => {
    const resultData = {
      long_description: dataParameter.long_description,
    };

    const role = getState().RolNavigationState?.state;

    const { data, status } = await axios.put(
      `${urls.URL_BASE}${urls.urlUpdateResultProcess}${resultId}/${role}`,
      resultData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(getResultsByProcessId(processId, token));
      return data;
    }
  };

export const deleteResultProcess =
  (processId, result_id, token) => async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;
    const stage_role = "/" + role;

    const { data, status } = await axios.delete(
      urls.URL_BASE + urls.urlDeleteResultProcess + result_id + stage_role,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (status === 200 || status === 201) {
      dispatch(getResultsByProcessId(processId, token));
      return data;
    }
  };
