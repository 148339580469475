import { useState, useRef, useEffect, useCallback } from "react";
import { TabMenu } from "primereact/tabmenu";

import { TextEditor } from "../../../components/UI";

import { useDispatch } from "react-redux";

import * as csActions from "../../../../redux/actions/complex-system/in-design-complex-system.actions";

const PROCESSES = "processes";
const PHENOMENONS = "phenomenons";
const ORGANIZATION_PATTERNS = "organization_patterns";
const FUNCTION = "function";
const MECHANISM_STEP = "mechanisms";

const wizardItems = [
  { id: 0, label: "Procesos", icon: "pi pi-table" },
  { id: 1, label: "Fenómenos", icon: "pi pi-cloud" },
  { id: 2, label: "Patrones de organización", icon: "pi pi-spinner" },
  { id: 3, label: "Función", icon: "pi pi-circle-on" },
];

const emptyComponent = {
  id: "",
  processes: "",
  phenomenons: "",
  organization_patterns: "",
  function: "",
};

const Mechanisms = () => {
  const dispatch = useDispatch();
  const toast = useRef();

  const [tabSelected, setTabSelected] = useState(wizardItems[0]);
  const [component, setComponent] = useState(emptyComponent);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const [_previousVersion, _setPreviousVersion] = useState(emptyComponent);

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (error) showMessage("error", "Error", error.message);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  const loadComponent = useCallback(async () => {
    try {
      const _retrieved_component =
        (await dispatch(csActions.getModelizationComponents(MECHANISM_STEP))) ??
        emptyComponent;
      setComponent({ ..._retrieved_component });
      _setPreviousVersion({ ..._retrieved_component });
    } catch (error) {
      setError(error);
    }
  }, [component]);

  useEffect(() => {
    // setLoading(true);
    loadComponent().then(() => {
      // setLoading(false);
    });
  }, []);

  const onInputChange = (e, name) => {
    const val = e.htmlValue || "";
    // let _modelizationComponents = { ...component };
    // _modelizationComponents[name] = val;
    // setComponent(_modelizationComponents);
    component[name] = val;
    if (_previousVersion[name] !== component[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const onSaveAttribute = async (attribute) => {
    setSubmitted(true);
    // setLoading(true);
    try {
      if (hasChanged) {
        await dispatch(
          csActions.updateModelizationComponents(
            component.id,
            attribute,
            component,
            MECHANISM_STEP
          )
        );
        setHasChanged(false);
        _setPreviousVersion(component);
        setComponent(component);
        showMessage("success", "Éxito", "Guardado.");
      }
    } catch (error) {
      setError(error);
    }
    // setLoading(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="card">
        <TabMenu
          model={wizardItems}
          onTabChange={(e) => {
            tabChangeHandler(e.value);
          }}
          activeItem={tabSelected}
        />

        {tabSelected.id === 0 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={PROCESSES}
            value={_previousVersion[PROCESSES]}
            title={"Procesos"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar procesos"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 1 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={PHENOMENONS}
            value={_previousVersion[PHENOMENONS]}
            title={"Fenómenos"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar fenómenos"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 2 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={ORGANIZATION_PATTERNS}
            value={_previousVersion[ORGANIZATION_PATTERNS]}
            title={"Patrones de organización"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar patrones de organización"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 3 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={FUNCTION}
            value={_previousVersion[FUNCTION]}
            title={"Función"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar función"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Mechanisms;
