import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import "./FilePickerDialog.scss";

export const FilePickerDialogHeader = ({ children }) => (
  <div className="app-file-picker-dialog-footer">{children}</div>
);

export const FilePickerDialogFooter = ({ children }) => (
  <div className="app-file-picker-dialog-footer">{children}</div>
);

const FilePickerDialog = (props) => {
  const { children, checkboxLabel, checked } = props;

  let headerContent = null;
  let footerContent = null;

  if (children) {
    React.Children.forEach(children, (child) => {
      if (child.type === FilePickerDialogHeader) {
        headerContent = child;
      } else if (child.type === FilePickerDialogFooter) {
        footerContent = child;
      }
    });
  }

  const maxFileSize = 100000000;

  const [files, setFiles] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [_checked, _setChecked] = useState(checked ?? false);

  const toast = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!error) {
      return;
    }
    console.error(error);
    showMessage("error", "Error", error);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const onSelectHandler = ({ files }) => {
    for (const file of files) {
      if ((file?.size ?? 0) > maxFileSize) {
        setError(
          file.name + " Tamaño de imagen super al límite permitido (1MB)"
        );
        return;
      }
    }
    setFiles(files);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    onSelectHandler({ files: files });
  };

  const onContinue = () => {
    if (!files?.length) return;

    const _files = [];
    for (const file of files) {
      _files.push(file);
    }
    handleReset();
    props.onContinue?.call(this, _files, _checked);
  };

  const onHideDialog = () => {
    handleReset();
    props.onHideDialog?.call(this);
  };

  const handleReset = () => {
    setFiles(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const dialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHideDialog}
      />

      <Button
        label="Sincronizar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={onContinue}
      />
    </>
  );

  const onCheckboxChange = (e) => {
    _setChecked(e.checked);
  };

  const dialogHeader = <h5>{props.header || "Selección de Archivos"}</h5>;

  const CheckBox = () => (
    <div className="p-field-checkbox">
      <Checkbox
        inputId={"app-file-picker-dialog-checkbox"}
        checked={_checked}
        onChange={onCheckboxChange}
      />
      <label htmlFor={"app-file-picker-dialog-checkbox"}>{checkboxLabel}</label>
    </div>
  );

  return (
    <Dialog
      modal
      className="p-fluid"
      style={{ width: "650px" }}
      contentStyle={{ minHeight: "100px" }}
      visible={props.visible}
      footer={dialogFooter}
      header={dialogHeader}
      onHide={onHideDialog}
    >
      <div className="app-file-picker-wrapper">
        {headerContent && <div className="item-header">{headerContent}</div>}

        <div className="app-file-picker-input">
          <label htmlFor="app-file-picker-input">
            Seleccionar {props.multiple ? "Archivos" : "Archivo"}
          </label>

          <input
            type="file"
            id="app-file-picker-input"
            onChange={handleFileChange}
            style={{ width: "100%" }}
            ref={fileInputRef}
            multiple={props.multiple}
            accept={props.accept}
          />
        </div>

        {footerContent && <div className="item-footer">{footerContent}</div>}

        {checkboxLabel && <CheckBox />}
      </div>
    </Dialog>
  );
};

export default FilePickerDialog;
