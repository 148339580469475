import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useForm from "../../../../hooks/useForm";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import {
  createPaymentMethod,
  updatePaymentMethod,
} from "../../../../redux/actions/payment-method.actions";
import { ImageUpload } from "../../../components/UI";
import { InputTextarea } from "primereact/inputtextarea";

const defaultImage =
  "https://gardensonquail.com/wp-content/uploads/2020/12/Image-Coming-Soon-400x400-1.jpg";

const emptyFunc = () => {
  return;
};
const emptyPaymentMethod = { name: "", logo: defaultImage, description: "" };

const PaymentMethodsFormDialog = React.memo(function PaymentMethodsFormDialog({
  open,
  setOpen,
  paymentMethod = emptyPaymentMethod,
  setPaymentMethod = emptyFunc,
  callback = emptyFunc,
}) {
  const [image, setImage] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { values, setValues, handleInputChange } = useForm(
    paymentMethod != null ? paymentMethod : emptyPaymentMethod
  );

  const user = useSelector((state) => state.LoginState.data);
  const toast = useRef(null);

  useEffect(() => {
    setValues(paymentMethod != null ? paymentMethod : emptyPaymentMethod);
  }, [paymentMethod]);

  const onInputChange = (e, name) => {
    let val;
    if (name === "logo") {
      val = e;
    } else {
      val = (e.target && e.target.value) || "";
    }
    let _paymentmethod = { ...values };
    _paymentmethod[`${name}`] = val;
    setValues(_paymentmethod);
  };

  const savePaymentMethod = async () => {
    setSubmitted(true);

    if (values?.name.trim() && values?.description.trim()) {
      //TODO Terminar de implementar el guardar y editar el metodo de pago con la imagen
      //let imgPaymentMethod;

      if (!values?.id) {
        createPaymentMethod(values, user?.access_token)
          .then((response) => {
            toast.current.show({
              severity: "success",
              summary: "PaymentMethod Created",
              detail: response?.name,
              life: 3000,
            });
            callback();
          })
          .catch(() => {
            toast.current.show({
              severity: "error",
              summary: "Error creating payment method",
              detail: "Error creating payment method",
              life: 3000,
            });
          });
      } else {
        updatePaymentMethod(values, user?.access_token)
          .then(() => {
            toast.current.show({
              severity: "success",
              summary: "PaymentMethod Updated",
              detail: values?.name,
              life: 3000,
            });
            callback();
          })
          .catch(() => {
            toast.current.show({
              severity: "success",
              summary: "Error updating payment method",
              detail: "Error updating payment method",
              life: 3000,
            });
          });
      }

      hideDialog();
    }
  };

  const hideDialog = () => {
    setPaymentMethod(emptyPaymentMethod);
    setValues(emptyPaymentMethod);
    setSubmitted(false);
    setOpen(false);
  };

  const paymentMethodDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={savePaymentMethod}
      />
    </>
  );

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        visible={open}
        style={{ width: "550px" }}
        header="Payment Method Details"
        modal
        className="p-fluid"
        footer={paymentMethodDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-field" style={{ textAlign: "left" }}>
          <label htmlFor="logo">Logo</label>

          <ImageUpload
            maxFileSize={1000000}
            image={image}
            setImage={setImage}
            onInputChange={onInputChange}
          />

          {submitted && !image && !values?.logo && (
            <small className="p-invalid">Se require una imagen.</small>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={values?.name}
            onChange={handleInputChange}
            required
            autoFocus
            autoComplete="off"
            placeholder="Write payment method name"
            className={submitted && !values?.name ? "p-invalid" : ""}
          />

          {submitted && !values?.name && (
            <small className="p-invalid color-danger">Name is required.</small>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="description">Description</label>
          <InputTextarea
            maxLength={300000}
            id="description"
            name="description"
            placeholder="Write payment method description"
            value={values?.description}
            autoComplete="off"
            onChange={handleInputChange}
            required
            className={submitted && !values?.description ? "p-invalid" : ""}
          />

          {submitted && !values?.description && (
            <small className="p-invalid color-danger">
              Description is required.
            </small>
          )}
        </div>
      </Dialog>
    </>
  );
});

PaymentMethodsFormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default PaymentMethodsFormDialog;
