import { createAction } from "redux-actions";
import api from "../../api";
import * as urls from "../../api/urls";

export const setLoadingKnowledgeAction = createAction(
  "SET_LOADING_KNOWLEDGE_ACTION"
);
export const setKnowledgeDataAction = createAction("SET_KNOWLEDGE_DATA_ACTION");
export const setKnowledgeErrorAction = createAction(
  "SET_KNOWLEDGE_ERROR_ACTION"
);
export const setResetKnowledgeAction = createAction(
  "SET_RESET_KNOWLEDGE_ACTION"
);

export const setLoadingCurrentKnowledgeAction = createAction(
  "SET_LOADING_CURRENT_KNOWLEDGE_ACTION"
);
export const setCurrentKnowledgeDataAction = createAction(
  "SET_CURRENT_KNOWLEDGE_DATA_ACTION"
);
export const setCurrentKnowledgeErrorAction = createAction(
  "SET_CURRENT_KNOWLEDGE_ERROR_ACTION"
);
export const setResetCurrentKnowledgeAction = createAction(
  "SET_RESET_CURRENT_KNOWLEDGE_ACTION"
);

export const setLoadingDistinctToolAction = createAction(
  "SET_LOADING_DISTINCT_TOOL_ACTION"
);
export const setDistinctToolDataAction = createAction(
  "SET_DISTINCT_TOOL_DATA_ACTION"
);
export const setDistinctToolErrorAction = createAction(
  "SET_DISTINCT_TOOL_ERROR_ACTION"
);
export const setResetDistinctToolAction = createAction(
  "SET_RESET_DISTINCT_TOOL_ACTION"
);

export const setLoadingComplementaryToolAction = createAction(
  "SET_LOADING_COMPLEMENTARY_TOOL_ACTION"
);
export const setComplementaryToolDataAction = createAction(
  "SET_COMPLEMENTARY_TOOL_DATA_ACTION"
);
export const setComplementaryToolErrorAction = createAction(
  "SET_COMPLEMENTARY_TOOL_ERROR_ACTION"
);
export const setResetComplementaryToolAction = createAction(
  "SET_RESET_COMPLEMENTARY_TOOL_ACTION"
);

export const setLoadingCreateToolAction = createAction(
  "SET_LOADING_CREATE_TOOL_ACTION"
);
export const setToolCreateDataAction = createAction(
  "SET_TOOL_CREATE_DATA_ACTION"
);
export const setToolCreateErrorAction = createAction(
  "SET_TOOL_CREATE_ERROR_ACTION"
);
export const setResetCreateToolAction = createAction(
  "SET_RESET_CREATE_TOOL_ACTION"
);

export const getAllKnowledge = (history) => async (dispatch, getState) => {
  dispatch(setResetKnowledgeAction());
  dispatch(setLoadingKnowledgeAction(true));

  try {
    const token = getState()?.LoginState?.data?.access_token;
    const { data, status } = await api.get(urls.urlListItems, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (status === 200) {
      dispatch(setKnowledgeDataAction(data));
      dispatch(
        setKnowledgeErrorAction({
          message: null,
        })
      );
    } else if (status === 403) {
      console.log("FORBIDEEEN");
      history.push("/access");
    }
  } catch (error) {
    console.log(error);
    dispatch(
      setKnowledgeErrorAction({
        message: "Error al recuperar información",
      })
    );
  }

  dispatch(setLoadingKnowledgeAction(false));
};

export const getKnowledgeById =
  (history, item_id) => async (dispatch, getState) => {
    dispatch(setResetCurrentKnowledgeAction());
    dispatch(setLoadingCurrentKnowledgeAction(true));

    try {
      const token = getState()?.LoginState?.data?.access_token;
      const { data, status } = await api.get(
        `${urls.urlListItems}/${item_id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (status === 200) {
        dispatch(setCurrentKnowledgeDataAction(data));
        dispatch(
          setCurrentKnowledgeErrorAction({
            message: null,
          })
        );
      } else if (status === 403) {
        console.log("FORBIDEEEN");
        history.push("/access");
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setCurrentKnowledgeErrorAction({
          message: "Error al recuperar información",
        })
      );
    }

    dispatch(setLoadingCurrentKnowledgeAction(false));
  };

export const createKnowledge = async (data, token) => {
  let dataCreate = {
    name: data?.name,
    keywords: data?.keywords,
    source_meaning: data?.source_meaning,
    local_meaning: data?.local_meaning,
    description: data?.description,
    folder: "knowledge",
    team_id: 0,
    type: 2,
    dataImage: data?.dataImage,
  };
  const response = await api.post(urls.urlCreateKnowledge, dataCreate, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return response.data;
};

export const updateKnowledge = async (data, token) => {
  let dataCreate = {
    name: data?.name,
    keywords: data?.keywords,
    source_meaning: data?.source_meaning,
    local_meaning: data?.local_meaning,
    description: data?.description,
    folder: "knowledge",
    team_id: 0,
    type: 2,
    dataImage: data?.dataImage,
  };
  const response = await api.put(
    `${urls.urlUpdateKnowledge}/${data?.id}`,
    dataCreate,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

export const createKnowledgeAditionalData = async (data, token) => {
  const { opcion, id } = data;
  let dataCreate = {
    id: id,
  };
  let url = "";

  if (opcion === "theory") {
    dataCreate.theory = data?.theory;
    url = urls.urlCreateKnowledgeTheory;
  } else if (opcion === "method") {
    dataCreate.method = data?.method;
    url = urls.urlCreateKnowledgeMethod;
  } else if (opcion === "purpose") {
    dataCreate.purpose = data?.purpose;
    url = urls.urlCreateKnowledgePurpose;
  } else if (opcion === "tool") {
    dataCreate.tool = data?.tool;
    url = urls.urlCreateKnowledgeTool;
  } else if (opcion === "duality") {
    dataCreate.duality = data?.duality;
    url = urls.urlCreateKnowledgeDuality;
  } else {
    dataCreate.interactions = data?.interactions;
    url = urls.urlCreateKnowledgeInteractions;
  }

  const response = await api.put(url, dataCreate, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return response.data;
};

export const cleanItem = () => async (dispatch) => {
  dispatch(setResetCurrentKnowledgeAction());
  dispatch(setLoadingCurrentKnowledgeAction(false));
  dispatch(setCurrentKnowledgeDataAction({}));
  dispatch(setCurrentKnowledgeErrorAction(null));
};
