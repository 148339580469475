import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { inDesignComplexSystemActions } from "../../../redux/actions/complex-system";
import { Divider } from "primereact/divider";

const ModelComponentsTable = (props) => {
  const [components, setComponents] = useState(props.data ?? []);
  const dispatch = useDispatch();

  const _setComponents = (components) => {
    components ??= [];
    setComponents(
      components.sort((a, b) => {
        return a.selected_component?.name?.localeCompare(
          b.selected_component?.name
        );
      })
    );
  };

  useEffect(() => {
    _setComponents(props.data);
  }, [props.data]);

  const onCheckboxChange = async (e) => {
    const index = components.findIndex((c) => c.id == e.target.value.id);
    let _component = components[index];

    if (e.target.name === "key") {
      _component.is_key = !e.target.value.is_key;
    } else if (e.target.name === "variable") {
      _component.is_variable = !e.target.value.is_variable;
    }

    try {
      const _upComponent = await dispatch(
        inDesignComplexSystemActions.updateSelectedComponent(_component)
      );
      const _components = [...components];
      _components[index] = _upComponent;
      _setComponents(_components);
      props.reloadGraph(true);
    } catch (error) {
      props.setError(error);
    }
  };

  const onRemoveSelectedComponent = async (id) => {
    try {
      await dispatch(inDesignComplexSystemActions.deleteSelectedComponent(id));
      const _components = components.filter((c) => c.component_id !== id);
      _setComponents(_components);
      props.reloadGraph(true);
    } catch (error) {
      props.setError(error);
    }
  };

  const CheckBox = ({ item, label, checked, name }) => (
    <div className="p-field-checkbox">
      <Checkbox
        id={`k_${item.id}`}
        inputId={`k_${item.id}`}
        name={name}
        checked={checked}
        value={item}
        onChange={onCheckboxChange}
      />
      <label htmlFor={`k_${item.id}`}>{label}</label>
    </div>
  );

  const Item = ({ item }) => (
    <div className="p-d-flex p-ai-center">
      <div className="p-d-flex p-flex-column" style={{ flexGrow: 1 }}>
        <strong className="p-mb-3">{item.selected_component?.name}</strong>

        <CheckBox
          key={"cbk_" + item.id}
          item={item}
          label={"Llave"}
          checked={item.is_key}
          name={"key"}
        />

        <CheckBox
          key={"cbv_" + item.id}
          item={item}
          label={"Variable"}
          checked={item.is_variable}
          name={"variable"}
        />
      </div>

      <div>
        <Button
          key={item.component_id}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            onRemoveSelectedComponent(item.component_id);
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      {components?.length ? (
        components?.map((item, index) => (
          <Fragment key={"ki_" + item.id}>
            <Item item={item} />

            {index !== components.length - 1 && <Divider />}
          </Fragment>
        ))
      ) : (
        <small style={{ color: "var(--text-color-secondary)" }}>
          Agregar o sincronizar componentes
        </small>
      )}
    </div>
  );
};

export default ModelComponentsTable;
