import COLORS from "../../../constants/Colors";
import { NUMBER_OF_FIRST_ORDER_SUBSISTEMS } from "../../../constants/TerritorialSubsistems";

class InteractionsGraphGenerator {
  constructor(nodes, edges) {
    this.nodes = nodes;
    this.edges = edges;
    this.groups = this.generateGroups();
  }

  getDegree = (edgesList, node) => {
    let ac = 0;
    for (let i = 0; i < edgesList.length; i++) {
      if (node.component_id == edgesList[i].source_id) {
        ac += 10; // Step for increasing size
        if (ac > 50) {
          // For not increasing the size forever
          break;
        }
      }
    }
    return ac;
  };

  prepareData(rawNodes, rawEdges) {
    let nodes = [];
    let edges = [];

    for (let i = 0; i < rawNodes?.length; i++) {
      const node = rawNodes[i];
      nodes.push({
        id: node.component_id,
        title:
          node.selected_component.definition?.length == 80
            ? node.selected_component.definition
            : node.selected_component.definition.slice(0, 80) + "...",
        label: node.selected_component.name,
        group: node.selected_component.group_id.toString(),
        shape: node.is_key ? "diamond" : "dot",
        size: 10 + this.getDegree(rawEdges, node),
        font: {
          size: 20,
        },
      });
    }

    for (let j = 0; j < rawEdges?.length; j++) {
      const edge = rawEdges[j];
      edges.push({
        from: edge.source_id,
        to: edge.target_id,
        width: edge.intensity_id == 1 ? 1 : edge.intensity_id == 2 ? 3 : 5,
        dashes: edge.is_key,
        label: `${edge.direction_id === 1 ? "+" : "-"}`,
        title: edge.name,
        color: {
          hover:
            this.groups[edge.source.selected_component.group_id.toString()]
              ?.color.border,
          color:
            this.groups[edge.source.selected_component.group_id.toString()]
              ?.color.background,
          highlight:
            this.groups[edge.source.selected_component.group_id.toString()]
              ?.color.border,
        },
        font: {
          size: 35,
        },
        arrows: {
          to: {
            enabled: true,
          },
        },
      });
    }

    return { nodes: nodes, edges: edges };
  }

  get components() {
    const components = this.prepareData(this.nodes, this.edges);
    return components;
  }

  get graph() {
    const { nodes, edges } = this.components;
    return {
      nodes: nodes,
      edges: edges,
    };
  }

  get options() {
    return {
      physics: {
        barnesHut: {
          springConstant: 0.005,
          avoidOverlap: 1,
        },
      },
      autoResize: true,
      groups: this.groups,
      layout: {
        hierarchical: false,
      },
      edges: {
        arrows: {
          to: false,
        },
        smooth: {
          type: "dynamic",
          forceDirection: "none",
          roundness: 0.2,
        },
      },
      nodes: {
        shape: "dot",
        size: 25,
        borderWidth: 2,
        font: {
          size: 35,
          color: "#343434",
          strokeWidth: 2,
          strokeColor: "#ffffff",
        },
      },
      interaction: {
        hover: true,
        hideEdgesOnDrag: true,
      },
    };
  }

  generateGroups() {
    let groups = {};
    for (let i = 0; i < NUMBER_OF_FIRST_ORDER_SUBSISTEMS; i++) {
      groups[i] = {
        color: {
          border: COLORS[i]?.outer,
          background: COLORS[i]?.inner,
          hover: {
            border: COLORS[i]?.outer,
            background: COLORS[i]?.hover,
          },
          highlight: {
            border: COLORS[i]?.outer,
            background: COLORS[i]?.hover,
          },
        },
      };
    }
    return groups;
  }
}

export default InteractionsGraphGenerator;
