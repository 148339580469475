import Graph from "react-graph-vis";

import "../../../../../node_modules/vis-network/dist/dist/vis-network.min.css";

const PickNodesGraph = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "max(calc(100vh - 300px), 400px)",
        background: "white",
        padding: 20,
      }}
    >
      <Graph
        graph={props.graph}
        options={props.options}
        events={props.events} // uncomment if it is need it to interact the UI
        getNetwork={() => {
          //  if you want access to vis.js network api you can set the state in a parent component using this property*/}
        }}
      />
    </div>
  );
};

export default PickNodesGraph;
