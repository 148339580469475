// React imports
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Template imports
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
// Custom UI imports
import ExecuteDialog from "../../components/UI/ModelsConsole/ExecuteDialog";
import {
  CreateModelFormDialog,
  ModelsListTable,
  GenericDialog,
} from "../../components/UI";
import { setModelState as setModelStore } from "../../../redux/actions/model.actions";
import { getModelsCategories } from "../../../redux/actions/model.actions";
import { uploadImages } from "../../../redux/actions/generics.action";
import { inDesignComplexSystemActions } from "../../../redux/actions/complex-system";
import { RolesEnum } from "../../enums/roles";
import { S3_MODEL_TYPE } from "../utils/types/s3_types";
import * as rolNavigationActions from "../../../redux/actions/rol-navigation.actions";
import * as modelActions from "../../../redux/actions/model.actions";
import FilePickerDialog from "../../components/UI/FilePickerDialog";
import { syncMainComponents } from "../../../redux/actions/sync.actions";

const defaultImage =
  "https://gardensonquail.com/wp-content/uploads/2020/12/Image-Coming-Soon-400x400-1.jpg";

const ModelsConsole = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.LoginState.data);
  const teamId = user?.team?.id.toString();

  const emptyModel = {
    id: null,
    name: "",
    logo: null,
    description: "",
    image: "",
    plan_id: null,
    status: true,
    categories_ids: [],
  };

  // MODULES LOGIC STATES
  const [model, setModel] = useState(emptyModel);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [modelsList, setModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState(null);
  const [deleteModelDialog, setDeleteModelDialog] = useState(false);
  const [openExecuteDialog, setOpenExecuteDialog] = useState(false);
  const [publishModelDialog, setPublishModelDialog] = useState(false);
  const [openCreateModelDialog, setCreateModelDialog] = useState(false);
  const [openKeepWorkingDialog, setOpenKeepWorkingDialog] = useState(false);
  const [openSyncComponentsDialog, setSyncComponentsDialog] = useState(false);

  // UI STATES, VARIABLE AND FUNCTIONS
  const toast = useRef(null);
  const role = props.location.state;

  useEffect(() => {
    dispatch(modelActions.resetModelState());
    dispatch(rolNavigationActions.setRolNavigation(props.location.state));
  }, []);

  useEffect(() => {
    if (error) {
      showMessage("error", "Error", error.message);
    }
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    let val;

    if (name === "categories_ids") val = e;
    else val = (e.target && e.target.value) || "";

    let _model = { ...model };
    _model[name] = val;
    setModel(_model);
  };

  const loadCategories = useCallback(async () => {
    try {
      const categories = await dispatch(getModelsCategories());
      setCategoriesList(categories);
    } catch (err) {
      setError(error?.message);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadCategories().then(() => {
      setIsLoading(false);
    });
  }, []);

  // For getting the list of models by user
  const loadModels = useCallback(async () => {
    try {
      const models = await modelActions.getModelsByTeam(
        teamId,
        user?.access_token,
        role
      );
      setModelsList(models);
    } catch (err) {
      setError(err);
      showMessage("error", "Error", "Ha ocurrido un error inesperado");
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadModels().then(() => {
      setIsLoading(false);
    });
  }, []);

  // For getting the one model which the user choose to work with
  const loadModel = async (modelId) => {
    try {
      dispatch(await modelActions.getModelById(modelId));
      return true;
    } catch (err) {
      setError(err);
    }
  };

  const publishModel = async () => {
    setIsLoading(true);

    try {
      const wasPublished = await dispatch(modelActions.publishModel(model.id));

      if (wasPublished) {
        // TODO: Create PUBLISH atribute in schema and update ?
        // or maybe this should be done in the same main backend
        // maybe here i need to update the redux with the published status

        setIsLoading(false);
        setPublishModelDialog(false);
        showMessage("success", "Éxito", "Modelo Publicado!");
      }
    } catch (error) {
      setIsLoading(false);
      setPublishModelDialog(false);
      setError(error);
    }
  };

  const keepWorkingOnSelectedModel = () => {
    setIsLoading(true);
    loadModel(model.id, teamId).then((result) => {
      if (result) {
        history.push({
          pathname: "/admin/model-designer",
          state: { modelId: model.id },
        });
      } else {
        setIsLoading(false);
      }
    });
  };

  const syncComponents = (files, download) => {
    const file = files["0"];
    if (!file) {
      showMessage("info", "Archivo no seleccionado");
      return;
    }

    const extension = file.name.split(".").at(-1);
    if (!extension) {
      showMessage("error", "Archivo no tiene extensión: " + file.name);
      return;
    }
    let filename = `main-components-data-source.${extension}`;

    setSyncComponentsDialog(false);

    showMessage("info", "Procesando...", "Espere al mensaje de confirmación");
    setIsLoading(true);

    dispatch(syncMainComponents(file, filename, download)).then((error) => {
      setIsLoading(false);
      if (error) {
        showMessage("error", "Error al sincronizar", error);
      } else {
        showMessage("success", "Sincronización completada");
      }
    });
  };

  const deleteModel = async () => {
    const status = await dispatch(
      modelActions.deleteModel(model.id, user.access_token, role)
    );

    if (status !== 200) {
      showMessage("error", "Error", "Modelo no eliminado");
      return;
    }

    let _models = modelsList.filter((val) => val.id !== model.id);
    setModelsList(_models);
    setDeleteModelDialog(false);
    setModel(emptyModel);
    showMessage("success", "Éxito", "Modelo Eliminado!");
  };

  const saveModel = async () => {
    setSubmitted(true);

    if (model?.name?.trim() && model?.description?.trim()) {
      try {
        setIsLoading(true);

        // Creating a new model
        if (!model?.id) {
          const _model = { ...model };
          _model.image = defaultImage;

          // Send to save the model and return the model
          let new_model = await dispatch(modelActions.createModel(_model));
          // Send to save the image in the bucket, and return the hole updated object
          // Maybe the image upload at this point should be optional (if conditional)
          if (image) {
            const imageData = {
              folder: "models",
              team_id: new_model.team_id,
              type: S3_MODEL_TYPE,
              type_id: new_model.id,
              dataImage: image,
            };
            try {
              new_model = await dispatch(uploadImages(imageData, role));
            } catch (e) {
              console.log(e);
            }
          }
          dispatch(modelActions.setModelState(new_model));
          // Intantiating complex system with id of new model
          dispatch(
            inDesignComplexSystemActions.instantiateCompleSystem(new_model.id)
          );

          showMessage("success", "Éxito", "Modelo Creado!");

          hideCreateModelDialog();

          history.push({
            pathname: "/admin/model-designer",
            state: { modelId: new_model.id },
          });
        } else {
          // Editing a model
          const _model = { ...model };

          let updated_model = await dispatch(
            modelActions.editModel(_model, role)
          );

          if (image) {
            // Send to save the image in the bucket, and return url
            const imageData = {
              team_id: updated_model.team_id,
              type: S3_MODEL_TYPE,
              type_id: updated_model.id,
              dataImage: image,
            };

            updated_model = await dispatch(uploadImages(imageData, role));
          }

          setSubmitted(false);
          hideCreateModelDialog();
          // Update model locally
          updateModelLocaly(updated_model);
          setIsLoading(false);
          showMessage("success", "Éxito", "Modelo Actualizado!");
        }
      } catch (e) {
        setError(e);
      }
    }
  };

  // Functions as handlers for clicking buttons
  const onModelClick = (selectedModel) => {
    setOpenKeepWorkingDialog(true);
    setModel(selectedModel);
  };

  const onDeleteModel = (r) => {
    setModel(r);
    setDeleteModelDialog(true);
  };

  const onEditBasicInfoModel = (model) => {
    setModel(model);
    setCreateModelDialog(true);
  };

  const onPublishModel = (model) => {
    setModel(model);
    setPublishModelDialog(true);
  };

  const openNew = () => {
    dispatch(setModelStore({}));
    setCreateModelDialog(true);
    setModel(model);
  };

  const openSyncComponents = () => {
    dispatch(setModelStore({}));
    setSyncComponentsDialog(true);
    setModel(model);
  };

  const onExecuteModel = (data) => {
    setOpenExecuteDialog(true);
    setModel(data);
  };

  // On change one model, this function update all list locally
  const updateModelLocaly = (data) => {
    let _models = [...modelsList];
    const index = _models.findIndex((model) => model?.id === data?.id);

    if (index !== -1) {
      _models[index] = data;
      setModelsList(_models);
    }
  };

  // Functions for when the Dialog hides, mostly creaning processes
  const hideCreateModelDialog = () => {
    setCreateModelDialog(false);
    setModel(emptyModel);
    setSubmitted(false);
  };

  const hideDeleteModelDialog = () => {
    setModel(emptyModel);
    setDeleteModelDialog(false);
  };

  const hidePublishModelDialog = () => {
    setModel(emptyModel);
    setPublishModelDialog(false);
  };

  const hideKeepWorkingDialog = () => {
    setModel(emptyModel);
    setOpenKeepWorkingDialog(false);
  };

  const hideSyncComponentsDialog = () => {
    setModel(emptyModel);
    setSyncComponentsDialog(false);
  };

  // BODY TEMPLATES

  const leftToolbarTemplate = () => {
    return (
      <div>
        {role === RolesEnum.Designer ? (
          <>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              className="p-button-success"
              onClick={openNew}
            />

            <Button
              label="Sincronizar"
              icon="pi pi-refresh"
              className="p-button-success p-ml-2"
              onClick={openSyncComponents}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="p-grid crud-demo">
        <div className="p-col-12">
          <div className="card">
            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />

            <ModelsListTable
              onEditBasicInfoModel={onEditBasicInfoModel}
              onDeleteModel={onDeleteModel}
              onPublishModel={onPublishModel}
              roleNav={role}
              onModelClick={onModelClick}
              modelsList={modelsList}
              onExecuteModel={onExecuteModel}
              isLoading={isLoading}
            />

            <CreateModelFormDialog
              hideCreateModelDialog={hideCreateModelDialog}
              openCreateModelDialog={openCreateModelDialog}
              saveModel={saveModel}
              model={model}
              submitted={submitted}
              onInputChange={onInputChange}
              categories={categoriesList}
              image={image}
              setImage={setImage}
              showMessage={showMessage}
            />

            <GenericDialog
              visible={deleteModelDialog}
              header={"Confirmar"}
              onCancelAction={() => setDeleteModelDialog(false)}
              onConfirmationAction={deleteModel}
              onHide={hideDeleteModelDialog}
              message={<span>¿Desea eliminar {model?.name}?</span>}
              condition={model}
            />

            <GenericDialog
              visible={publishModelDialog}
              header={"Confirmar"}
              onCancelAction={() => setPublishModelDialog(false)}
              onConfirmationAction={publishModel}
              onHide={hidePublishModelDialog}
              message={<span>¿Desea publicar {model?.name}?</span>}
              condition={model}
            />

            <GenericDialog
              visible={openKeepWorkingDialog}
              header={"Confirmar"}
              onCancelAction={hideKeepWorkingDialog}
              onConfirmationAction={keepWorkingOnSelectedModel}
              onHide={hideKeepWorkingDialog}
              message={
                <span>
                  ¿Seguir trabajando en <b>{model?.name}</b>?
                </span>
              }
              condition={model}
            />

            <FilePickerDialog
              visible={openSyncComponentsDialog}
              onHideDialog={hideSyncComponentsDialog}
              onContinue={syncComponents}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              checkboxLabel="¿Descargar salida?"
            />

            <ExecuteDialog
              open={openExecuteDialog}
              setOpen={setOpenExecuteDialog}
              onExecute={updateModelLocaly}
              showMessage={showMessage}
              selectedModel={model}
              user={user}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelsConsole;
