import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "../../components/custom/primereact/components/utils/ClassNames";
import { useSelector } from "react-redux";

import { ImageUpload } from "../../components/UI";
import { updateTeam } from "../../../redux/actions/team.actions";
import { uploadImage } from "../../../redux/actions/generics.action";
import { S3_TEAM_TYPE } from "../utils/types/s3_types";

const TeamFormDialog = ({ open, setOpen, team, setTeam }) => {
  const toast = useRef(null);
  const user = useSelector((state) => state.LoginState?.data);

  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);

  useEffect(() => {
    setDescription(team?.description);
  }, [team]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!description.trim() > 0) {
      setErrors({
        description: {
          status: true,
          message: "Descripción vacía",
        },
      });
      return;
    }

    const data = {
      description,
    };

    updateTeam(team?.id, data, user?.access_token)
      .then((data) => {
        setTeam(data);
        setErrors({});
        updateImageTeam();
        toast.current.show({
          severity: "success",
          summary: "Datos atualizados",
          detail: team?.name,
          life: 2000,
        });
      })
      .catch((err) => {
        logger.error(err);
        toast.current.show({
          severity: "error",
          summary: "No actualizado",
          detail: "Ha ocurrido un error inesperado",
          life: 2000,
        });
      });
  };

  const updateImageTeam = () => {
    if (image && team?.id) {
      const body = {
        team_id: team?.id,
        type: S3_TEAM_TYPE,
        type_id: team?.id,
        dataImage: image,
      };

      uploadImage(body)
        .then((data) => {
          setImage(null);
          setTeam(data);
        })
        .catch((err) => {
          logger.error(err);
          toast.current.show({
            severity: "error",
            summary: "Imagen no actualizada",
            detail: "Intente con otra imagen",
            life: 2000,
          });
        });
    }
  };

  const onImageChange = (e) => {
    setImage(e);
  };

  const headerDialogHeader = () => (
    <div>
      <h5>Editar equipo</h5>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />

      <Dialog
        header={headerDialogHeader}
        visible={open}
        onHide={() => setOpen(false)}
        style={{ width: "450px" }}
        modal
      >
        <form onSubmit={handleSubmit}>
          <div className="p-fluid">
            <div className="p-field">
              <label>Logo</label>

              <ImageUpload
                maxFileSize={1000000}
                image={image}
                setImage={setImage}
                onInputChange={onImageChange}
                chooseOptions={{ style: { width: "100%" } }}
              />
            </div>

            <div className="p-field">
              <label className="required">Descripción</label>

              <InputTextarea
                value={description}
                placeholder="Escribe la descripción del equipo"
                autoComplete="off"
                required
                onChange={(e) => setDescription(e.target.value)}
                autoResize={true}
                maxLength={1000}
                rows={15}
                className={classNames({
                  "p-invalid": errors?.description?.status === true,
                })}
              />

              {errors?.description?.status === true && (
                <small className="color-danger">
                  {errors?.description?.message}
                </small>
              )}
            </div>

            <div className="p-field p-text-center">
              <Button
                disabled={description === team?.description && !image}
                label="Guardar"
                className="p-button-success p-button-outlined"
                type="submit"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

TeamFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default TeamFormDialog;
