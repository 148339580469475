import { Autocomplete, Typography } from "@mui/material";
import TextField from "@material-ui/core/TextField";
import { v4 as uuid } from "uuid";
import {
  MEASURE_TYPE_STRING,
  QUESTION_TYPE_CLOSE,
  QUESTION_TYPE_MULTIPLE,
  QUESTION_TYPE_OPEN,
  SUB_VARIABLE_INTEGER_SCALE,
  type_options,
  type_questions,
  type_sub_variable_integer,
} from "../../constants/Surveys";

const CreateTypeQuestion = ({
  type,
  index,
  questions,
  setQuestions,
  toggleValue,
  optionType,
  setOptionType,
}) => {
  const handleTypeQuestion = (typeQuestion, index) => {
    let typeOfQuestion = [...questions];
    typeOfQuestion[index].variable = {};
    typeOfQuestion[index].type_option = {};
    typeOfQuestion[index].question_options = [];

    switch (typeQuestion) {
      case "OM":
        setOptionType(QUESTION_TYPE_MULTIPLE);
        typeOfQuestion[index].type_option = QUESTION_TYPE_MULTIPLE;
        setQuestions(typeOfQuestion);

        break;
      case "OU":
        setOptionType(QUESTION_TYPE_CLOSE);
        typeOfQuestion[index].type_option = QUESTION_TYPE_CLOSE;
        setQuestions(typeOfQuestion);
        break;

      case "PA":
        setOptionType(QUESTION_TYPE_OPEN);
        typeOfQuestion[index].type_option = QUESTION_TYPE_OPEN;
        setQuestions(typeOfQuestion);
        break;

      default:
        break;
    }
  };

  const handleStringOptionTypeQuestion = (typeOption, index) => {
    let variableType = [...questions];
    variableType[index].variable = {};
    variableType[index].question_options = [];

    switch (typeOption) {
      case 1:
        variableType[index].variable.variable_type = type_options.find(
          (s) => s?.id === 1
        );
        variableType[index].variable.measure_type = SUB_VARIABLE_INTEGER_SCALE;
        setQuestions(variableType);
        break;
      case 2:
        variableType[index].variable.variable_type = type_options.find(
          (s) => s?.id === 2
        );
        variableType[index].variable.measure_type = SUB_VARIABLE_INTEGER_SCALE;
        setQuestions(variableType);
        break;
      case 3:
        variableType[index].variable.variable_type = type_options.find(
          (s) => s?.id === 3
        );
        variableType[index].variable.measure_type = MEASURE_TYPE_STRING;
        setQuestions(variableType);
        break;
      default:
        break;
    }
  };

  const handleIntegerOptionQuestion = (typeOption, index) => {
    let variableType = [...questions];
    variableType[index].variable = {};
    variableType[index].question_options = [];

    switch (typeOption) {
      case 2:
        variableType[index].variable.variable_type = type_options.find(
          (s) => s?.id === 1
        );
        variableType[index].variable.measure_type =
          type_sub_variable_integer.find((s) => s?.id === 2);
        variableType[index].question_options.push({
          id: uuid(),
          key: 1,
          value: "",
          selected: false,
        });
        setQuestions(variableType);
        break;
      case 3:
        variableType[index].variable.variable_type = type_options.find(
          (s) => s?.id === 1
        );
        variableType[index].variable.measure_type =
          type_sub_variable_integer.find((s) => s?.id === 3);
        variableType[index].question_options.push({
          id: uuid(),
          key: 1,
          value: "",
          selected: false,
        });
        setQuestions(variableType);
        break;

      default:
        break;
    }
  };

  return (
    <div className="p-pl-2 p-pr-3">
      <Typography variant="h6" className="p-mb-2">
        {" "}
        Tipo de pregunta:
      </Typography>
      <div style={{ display: "block" }}>
        <div>
          <Autocomplete
            onChange={(event, option) => {
              if (option !== null) {
                handleTypeQuestion(option?.id, index);
              }
            }}
            disabled={toggleValue}
            options={type_questions}
            sx={{ width: 200 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Tipo de pregunta" />
            )}
          />
        </div>
        <div
          className="p-mt-1 p-mb-1"
          hidden={optionType !== QUESTION_TYPE_OPEN || toggleValue}
        >
          <Autocomplete
            onChange={(event, option) => {
              if (option !== null) {
                handleStringOptionTypeQuestion(option?.id, index);
              }
            }}
            disabled={toggleValue}
            options={type_options}
            sx={{ width: 200 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="Opciones" />}
          />
        </div>

        <div
          className="p-mt-1 p-mb-1"
          hidden={optionType === QUESTION_TYPE_OPEN || toggleValue}
        >
          <Autocomplete
            onChange={(event, option) => {
              if (option !== null) {
                handleIntegerOptionQuestion(option?.id, index);
              }
            }}
            disabled={toggleValue}
            options={type_sub_variable_integer}
            sx={{ width: 200 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="Opciones" />}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTypeQuestion;
