import axios from "axios";
import { createAction } from "redux-actions";
import * as urls from "../../api/urls";

export const setSynchronizing = createAction("SET_SYNCHRONIZING");

export const setSynchronizingResponse = createAction(
  "SET_SYNCHRONIZING_RESPONSE"
);

export const syncMainComponents =
  (file, filename, download = false) =>
  async (dispatch, getState) => {
    return postSyncFile(
      urls.urlSyncMainComponents,
      file,
      filename,
      download,
      dispatch,
      getState
    );
  };

export const syncCompositions =
  (file, filename, download = false) =>
  async (dispatch, getState) => {
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;
    return postSyncFile(
      `${urls.urlSyncCompositions}/${modelId}?role=${role}`,
      file,
      filename,
      download,
      dispatch,
      getState
    );
  };

export const syncInteractions =
  (file, filename, download = false) =>
  async (dispatch, getState) => {
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;
    return postSyncFile(
      `${urls.urlSyncInteractions}/${modelId}?role=${role}`,
      file,
      filename,
      download,
      dispatch,
      getState
    );
  };

const postSyncFile = async (
  urlService,
  file,
  filename,
  download,
  dispatch,
  getState
) => {
  const token = getState()?.LoginState?.data?.access_token;
  let error = null;
  try {
    const formData = new FormData();
    formData.append("file", file);

    dispatch(setSynchronizing(true));
    dispatch(setSynchronizingResponse());

    const response = await axios({
      url: urlService,
      method: "POST",
      responseType: "blob",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (!response.data) {
      throw new Error();
    }

    if (!download) return null;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    error = "Error al realizar la sincronización de " + file.name;
    dispatch(setSynchronizingResponse(error));
    console.error(e);
  }
  dispatch(setSynchronizing(false));
  return error;
};
