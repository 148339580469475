import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import AppTopbar from "./app/layouts/AppTopbar";
import AppFooter from "./app/layouts/AppFooter";
import AppConfig from "./app/layouts/AppConfig";
import AppRightPanel from "./app/layouts/AppRightPanel";
import PrimeReact from "primereact/api";
import Routes from "./app/routes/routes";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Help } from "./app/pages/Help";
import {
  listAcquiredModelsByTeam,
  retrieveAcquiredModelById,
} from "./redux/actions/acquireds/model.actions";
import { RolesEnum } from "./app/enums/roles";
import { setRolNavigation } from "./redux/actions/rol-navigation.actions";
import {
  createUserPreferences,
  retrieveUserPreferencesByUser,
  setAttributeUIAction,
  setUIAction,
  updateUserPreferences,
} from "./redux/actions/user-preferences.actions";
import { IN_EXECUTION_STATUS } from "./app/pages/utils/constants/modelConstans";
import { Toast } from "primereact/toast";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.scss";
import "./app/assets/scss/modelos.scss";
import "./app/assets/scss/custom-global.scss";

const App = () => {
  const toast = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.LoginState.data);
  const ui = useSelector((state) => state.UserPreferencesState.ui);

  const [resetActiveIndex, setResetActiveIndex] = useState(null);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(
    ui?.sidebar_static || false
  );
  const [sidebarActive, setSidebarActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState(ui?.menu_type || "horizontal");
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState(
    ui?.input_background || "filled"
  );
  const [rippleEffect, setRippleEffect] = useState(ui?.ripple_effect || false);
  const [rightPanelActive, setRightPanelActive] = useState(false);
  const [colorScheme, setColorScheme] = useState(ui?.color_scheme || "dark");
  const [topbarScheme, setTopbarScheme] = useState("dark");
  const [menuScheme, setMenuScheme] = useState("dark");
  const [themeScheme, setThemeScheme] = useState("dark");
  const [theme, setTheme] = useState(ui?.theme_color || "purple");
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [modelMenu, setModelMenu] = useState([]);

  useEffect(() => {
    /**
     * Check if ui attribute exist
     * Check if ui attribute was changed (different to redux valus)
     */
    if (ui?.color_scheme && colorScheme !== ui?.color_scheme) {
      setColorScheme(ui?.color_scheme);
    }

    if (ui?.input_background && inputStyle !== ui?.input_background) {
      setInputStyle(ui?.input_background);
    }

    if (ui?.menu_type && menuMode !== ui?.menu_type) {
      setMenuMode(ui?.menu_type);
    }

    if (ui?.theme_color && theme !== ui?.theme_color) {
      setTheme(ui?.theme_color);
    }

    if (ui?.sidebar_static && sidebarStatic !== ui?.sidebar_static) {
      setSidebarStatic(ui?.sidebar_static);
    }

    if (ui?.ripple_effect !== undefined && rippleEffect !== ui?.ripple_effect) {
      setRippleEffect(ui?.ripple_effect);
      PrimeReact.ripple = rippleEffect;
    }
  }, [ui]);

  // Load user preferences, or create user preferences if dont exist
  useEffect(() => {
    if (!ui?.user_id) {
      retrieveUserPreferencesByUser(user?.id, user?.access_token).then(
        (data) => {
          if (data?.id) {
            dispatch(setUIAction(data));
          } else {
            const data = {
              user_id: user?.id,
              color_scheme: colorScheme,
              menu_type: menuMode,
              ripple_effect: rippleEffect,
              input_background: inputStyle,
              theme_color: theme,
            };
            createUserPreferences(data, user?.access_token).then((data) => {
              dispatch(setUIAction(data));
            });
          }
        }
      );
    }
  }, [user]);

  const handleModelClick = async (model) => {
    if (model?.id && model?.status === IN_EXECUTION_STATUS) {
      dispatch(await retrieveAcquiredModelById(model?.id, user?.team?.id));
      dispatch(await setRolNavigation(RolesEnum.Executor));

      history.push({
        pathname: "/admin/model-designer",
        state: { modelId: model?.id },
      });
    } else {
      history.push({
        pathname: "/executor/models",
        state: RolesEnum.Executor,
      });
    }
  };

  useEffect(() => {
    listAcquiredModelsByTeam(user?.team.id, user?.access_token).then(
      (acquireds) => {
        if (acquireds) {
          const subMenu = [];

          acquireds.forEach((model, index) => {
            if (index < 4) {
              subMenu.push({
                label: index + 1 + ". " + model?.name,
                icon: "pi pi-globe",
                command: () => handleModelClick(model),
              });
            }
          });
          setModelMenu(subMenu);
        }
      }
    );
  }, [user]);

  const menu = [
    {
      label: "Dashboard",
      icon: "pi pi-home",
      to: "/",
    },
    {
      label: "Administración",
      icon: "pi pi-user",
      items: [
        { label: "Mi equipo", icon: "pi pi-users", to: "/admin/members" },
        { label: "Mi Plan", icon: "pi pi-cog", to: "/admin/plan/manage" },
        { label: "Personas", icon: "pi pi-user", to: "/admin/persons" },
        { label: "Planes", icon: "pi pi-dollar", to: "/admin/plan" },
        { label: "Roles", icon: "pi pi-briefcase", to: "/admin/role" },
        { label: "Equipos", icon: "pi pi-users", to: "/admin/team" },
        {
          label: "Métodos de pago",
          icon: "pi pi-money-bill",
          to: "/admin/payment-method",
        },
      ],
    },
    {
      label: "Mis MIT",
      icon: "pi pi-globe",
      items: [
        {
          label: "MITs recientes",
          disabled: true,
        },
        ...modelMenu,
        {
          separator: true,
          disabled: true,
        },
        {
          label: "MIT (Todos)",
          icon: "pi pi-list",
          to: "/executor/models",
          role: RolesEnum.Executor,
          // command: () => { history.push('/executor/models?status=asdsd') },
        },
      ],
    },
    {
      label: "Mis Apps",
      icon: "pi pi-desktop",
      items: [
        {
          label: "Solucionador de Problemas",
          icon: "pi pi-compass",
          to: "/apps/problem-solver",
        },
        {
          label: "M&E",
          icon: "pi pi-chart-line",
          to: "/apps/monitoring-and-evaluation",
        },
      ],
    },
    {
      label: "Tienda",
      icon: "pi pi-shopping-cart",
      to: "/models",
    },
    {
      label: "Crea en Gearth",
      icon: "pi pi-plus-circle",
      to: "/creators/model-designer",
      role: RolesEnum.Designer,
    },
  ];

  let menuClick;
  let rightPanelClick;
  let configClick;
  let searchClick;
  let topbarUserMenuClick;

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const saveUserPreferences = () => {
    // Update user preferences
    if (ui?.user_id) {
      const data = {
        color_scheme: ui?.color_scheme,
        menu_type: ui?.menu_type,
        ripple_effect: ui?.ripple_effect,
        input_background: ui?.input_background,
        theme_color: ui?.theme_color,
      };

      updateUserPreferences(ui?.id, data, user?.access_token)
        .then(() => {
          showMessage(
            "success",
            "Preferencias guardadas",
            "Refrescar si es necesario"
          );
        })
        .catch((err) => {
          console.error(err);
          showMessage("error", "Preferencias no guardadas", "Error inesperado");
        });
    }
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setResetActiveIndex(true);
      hideOverlayMenu();
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }
  };

  const onMenuClick = (event) => {
    if (menuActive && event.target.className === "layout-menu-container") {
      setResetActiveIndex(true);
      setMenuActive(false);
    }
    menuClick = true;
  };

  const onMenuModeChange = (menuMode) => {
    dispatch(setAttributeUIAction({ menu_type: menuMode }));
    // setMenuMode(menuMode);

    if (menuMode === "sidebar") {
      if (sidebarStatic) {
        setSidebarActive(true);
      }
    } else {
      setSidebarActive(false);
      if (topbarScheme !== menuScheme) {
        setMenuScheme(topbarScheme);
      }
    }

    if (topbarScheme === "dark") {
      setThemeScheme("dark");
    }
  };

  const onColorSchemeChange = (scheme) => {
    dispatch(setAttributeUIAction({ color_scheme: scheme }));
  };

  const onThemeSchemeChange = (scheme) => {
    setThemeScheme(scheme);
    setMenuScheme(scheme);
    setTopbarScheme(scheme);
  };

  const onTopbarSchemeChange = (scheme) => {
    setTopbarScheme(scheme);
  };

  const onMenuSchemeChange = (scheme) => {
    setMenuScheme(scheme);
  };

  const onThemeChange = (themeColor) => {
    dispatch(setAttributeUIAction({ theme_color: themeColor }));
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setRightPanelActive(false);

    if (isMobile()) {
      setStaticMenuMobileActive(
        (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
      );
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }
    event.preventDefault();
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const hideOverlayMenu = () => {
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const onRightPanelClick = () => {
    rightPanelClick = true;
  };

  const onRightPanelButtonClick = () => {
    setRightPanelActive((prevState) => !prevState);
    rightPanelClick = true;
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const onTopbarSearchToggle = () => {
    setSearchActive((prevState) => !prevState);
    searchClick = true;
  };

  const onTopbarSearchClick = () => {
    searchClick = true;
  };

  const onTopbarUserMenuClick = () => {
    setTopbarUserMenuActive((prevState) => !prevState);
    topbarUserMenuClick = true;
  };

  const onInputStyleChange = (inputStyle) => {
    dispatch(setAttributeUIAction({ input_background: inputStyle }));
  };

  const onRippleChange = (e) => {
    dispatch(setAttributeUIAction({ ripple_effect: e.value }));
    // PrimeReact.ripple = e.value;
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      setSearchActive(false);
      searchClick = false;
    }

    if (!topbarUserMenuClick && topbarUserMenuActive) {
      setTopbarUserMenuActive(false);
      topbarUserMenuClick = false;
    }

    if (!rightPanelClick && rightPanelActive) {
      setRightPanelActive(false);
    }

    if (!configClick && configActive) {
      setConfigActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setResetActiveIndex(true);
        setMenuActive(false);
      }

      if (staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    searchClick = false;
    topbarUserMenuClick = false;
    rightPanelClick = false;
    configClick = false;
    menuClick = false;
  };

  const onSidebarMouseOver = () => {
    setSidebarActive(!isMobile());
  };

  const onSidebarMouseLeave = () => {
    setSidebarActive(false);
  };

  const onToggleMenu = (event) => {
    menuClick = true;
    dispatch(setAttributeUIAction({ sidebar_static: !sidebarStatic }));
    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const layoutClassName = classNames(
    "layout-wrapper",
    {
      "layout-sidebar": menuMode === "sidebar",
      "layout-static": menuMode === "sidebar" && sidebarStatic,
      "layout-horizontal": menuMode === "horizontal",
      "layout-rightpanel-active": rightPanelActive,
      "layout-slim": menuMode === "slim",
      "layout-mobile-active": staticMenuMobileActive,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !rippleEffect,
    },
    "layout-menu-" + menuScheme + " layout-topbar-" + topbarScheme
  );

  return (
    <>
      <Toast ref={toast} />

      <div className={layoutClassName} onClick={onDocumentClick}>
        <AppTopbar
          topbarScheme={topbarScheme}
          onRightPanelButtonClick={onRightPanelButtonClick}
          searchActive={searchActive}
          onTopbarSearchToggle={onTopbarSearchToggle}
          onTopbarSearchClick={onTopbarSearchClick}
          topbarUserMenuActive={topbarUserMenuActive}
          onTopbarUserMenuClick={onTopbarUserMenuClick}
          menu={menu}
          menuActive={menuActive}
          onRootMenuItemClick={onRootMenuItemClick}
          mobileMenuActive={staticMenuMobileActive}
          onMenuItemClick={onMenuItemClick}
          menuMode={menuMode}
          sidebarStatic={sidebarStatic}
          sidebarActive={sidebarActive}
          onSidebarMouseOver={onSidebarMouseOver}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onToggleMenu={onToggleMenu}
          onMenuButtonClick={onMenuButtonClick}
          resetActiveIndex={resetActiveIndex}
          onMenuClick={onMenuClick}
        />

        <AppRightPanel onRightPanelClick={onRightPanelClick} />

        <AppConfig
          configActive={configActive}
          onConfigButtonClick={onConfigButtonClick}
          onConfigClick={onConfigClick}
          menuMode={menuMode}
          onMenuModeChange={onMenuModeChange}
          ripple={rippleEffect}
          onRippleChange={onRippleChange}
          inputStyle={inputStyle}
          onInputStyleChange={onInputStyleChange}
          colorScheme={colorScheme}
          onColorSchemeChange={onColorSchemeChange}
          topbarScheme={topbarScheme}
          onTopbarSchemeChange={onTopbarSchemeChange}
          menuScheme={menuScheme}
          onMenuSchemeChange={onMenuSchemeChange}
          themeScheme={themeScheme}
          onThemeSchemeChange={onThemeSchemeChange}
          theme={theme}
          onThemeChange={onThemeChange}
          onSave={saveUserPreferences}
          // setColorScheme={ setColorScheme }
        />

        <div className="layout-main">
          <div className="layout-content">
            <Routes colorScheme={colorScheme} help={Help} />
          </div>

          <AppFooter />
        </div>

        <div className="layout-mask modal-in"></div>
      </div>
    </>
  );
};

export default App;
