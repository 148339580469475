import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";

import { ListBox } from "primereact/listbox";

import Spinner from "../../../components/UI/spinner/Spinner";
import { ScrollTop } from "primereact/scrolltop";

import { inDesignComplexSystemActions } from "../../../../redux/actions/complex-system";
import { ScrollPanel } from "primereact/scrollpanel";
import { Dropdown } from "primereact/dropdown";

import { CreateObjectDialog, DeleteObjectDialog } from "../../../components/UI";
import ComponentsRelationshipsTable from "../../../components/UI/ComponentsRelationshipsTable";

import { Button } from "primereact/button";
import FilePickerDialog from "../../../components/UI/FilePickerDialog";
import { syncInteractions } from "../../../../redux/actions/sync.actions";

const buildGraphRepresentation = (components, interactions) => {
  let graph = {};
  for (let i in components) {
    graph[components[i].component_id] = interactions
      .filter((k) => k.source_id === components[i].component_id)
      .map((j) => j.target);
  }
  return graph;
};

const emptyInteraction = {
  id: null,
  model_id: "",
  source_id: "",
  target_id: "",
  is_key: "",
  intensity_id: "",
  direction_id: "",
  label: "",
  definition: "",
};

const Structure = (props) => {
  const { reloadGraph } = props;

  const [interaction, setInteraction] = useState(emptyInteraction);

  const [createOrEditLabel, setCreateOrEditLabel] = useState("");
  const [deleteDialogTitle, setDeleteDialogTitle] = useState("");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [createDialog, setCreateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [modelComponents, setModelComponents] = useState();
  const [sourceComponent, setSourceComponent] = useState();
  const [interactionsGraphRep, setInteractionsGraphRep] = useState();
  const [interactions, setInteractions] = useState();

  const [openSyncComponentsDialog, setSyncComponentsDialog] = useState(false);

  const toast = useRef(null);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _interaction = { ...interaction };
    _interaction[`${name}`] = val;
    setInteraction(_interaction);
  };

  useEffect(() => {
    if (error) {
      showMessage("error", "Error", error.message);
    }
  }, [error]);

  const loadModelComponents = useCallback(async () => {
    try {
      // Retrieving components
      const fetchedModelComponents = await dispatch(
        inDesignComplexSystemActions.getComponentsAddedToModel()
      );

      const fetchedModelInteractions = await dispatch(
        inDesignComplexSystemActions.getInteractions()
      );

      setModelComponents(fetchedModelComponents);
      setSourceComponent(fetchedModelComponents[0]);
      setInteractions(fetchedModelInteractions);
      const graph = buildGraphRepresentation(
        fetchedModelComponents,
        fetchedModelInteractions
      );

      setInteractionsGraphRep(graph);
      reloadGraph(true);
    } catch (error) {
      setError(error);
      return error;
    }
  }, [reloadGraph, dispatch]);

  useEffect(() => {
    setLoading(true);
    loadModelComponents().then(() => {
      setLoading(false);
    });
  }, [loadModelComponents]);

  const onUpdateInteraction = async (e) => {
    let _interaction = e ? { ...e.value } : { ...interaction };
    const target = e ? e.target.name : "";

    if (target === "is_key") _interaction.is_key = !_interaction.is_key;
    if (target === "positive") _interaction.direction_id = 1;
    if (target === "negative") _interaction.direction_id = 2;
    if (target === "weak") _interaction.intensity_id = 1;
    if (target === "medium") _interaction.intensity_id = 2;
    if (target === "strong") _interaction.intensity_id = 3;

    try {
      const upInteraction = await dispatch(
        inDesignComplexSystemActions.updateInteraction(_interaction)
      );
      const index = interactions.findIndex((i) => i.id === upInteraction.id); // Using ID of the record in the db
      let _interactions = [...interactions];
      _interactions[index].is_key = upInteraction.is_key;
      _interactions[index].direction_id = upInteraction.direction_id;
      _interactions[index].intensity_id = upInteraction.intensity_id;
      _interactions[index].name = upInteraction.name;
      _interactions[index].definition = upInteraction.definition;
      setInteractions(_interactions);
      props.reloadGraph(true);
    } catch (error) {
      setError(error);
    }
  };

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const createInteraction = async () => {
    if (interaction.id) {
      onUpdateInteraction(null);
      setSubmitted(false);
      setCreateDialog(false);
      return;
    }

    setSubmitted(true);
    setError(null);

    try {
      const _interaction = await dispatch(
        inDesignComplexSystemActions.createInteraction(interaction)
      );
      let _interactionsGraph = { ...interactionsGraphRep };
      // Updating element for list box
      const [_targetComp] = modelComponents.filter(
        (c) => c.component_id === _interaction.target_id
      );
      _interactionsGraph[`${sourceComponent.component_id}`].push(_targetComp);
      setInteractionsGraphRep(_interactionsGraph);
      // Upadtiong element for datatable
      setInteractions([_interaction, ...interactions]);
      props.reloadGraph(true);
    } catch (error) {
      setError(error);
    }

    setSubmitted(false);
    setCreateDialog(false);
  };

  const deleteInteraction = async () => {
    setError(null);
    try {
      await dispatch(
        inDesignComplexSystemActions.deleteInteraction(
          interaction.source_id,
          interaction.target_id
        )
      );
      // Removing from listbox
      let _interactionsGraph = { ...interactionsGraphRep };
      _interactionsGraph[sourceComponent.component_id] = interactionsGraphRep[
        sourceComponent.component_id
      ].filter((c) => c.component_id !== interaction.target_id);
      setInteractionsGraphRep(_interactionsGraph);

      // Removing from data table
      const _interaction = [...interactions].filter(
        (c) =>
          !(
            c.source_id === interaction.source_id &&
            c.target_id === interaction.target_id
          )
      );
      setInteractions(_interaction);
      props.reloadGraph(true);
      showMessage("success", "Éxito", "Interacción Eliminada!");
    } catch (error) {
      setError(error);
    }
    setDeleteDialog(false);
    setInteraction(emptyInteraction);
  };

  // On Buttons Cliks
  const onDeleteComponent = (interaction) => {
    setInteraction(interaction);
    setDeleteDialog(true);
  };

  const onInteractionSelected = (componentsSelected) => {
    setInteraction(emptyInteraction);

    const toAdd = componentsSelected.filter(
      (c) =>
        !interactionsGraphRep[sourceComponent.component_id]
          ?.map((i) => i.component_id)
          .includes(c)
    );

    const toRemove = interactionsGraphRep[sourceComponent.component_id]
      ?.map((i) => i.component_id)
      ?.filter((c) => !componentsSelected?.includes(c));

    let _interaction = emptyInteraction;
    _interaction.source_id = sourceComponent.component_id; // This is the id of the compoenent in the territorial system

    if (toAdd.length > 0) {
      _interaction.target_id = toAdd[0];
      setInteraction(_interaction);
      setCreateDialog(true);
      setCreateOrEditLabel(
        `Crear interacción: ${sourceComponent.selected_component.name} -> ${
          modelComponents.find((c) => c.component_id === toAdd[0])
            .selected_component.name
        }`
      );
    } else {
      _interaction.target_id = toRemove[0];
      setInteraction(_interaction);
      setDeleteDialog(true);
      setDeleteDialogTitle(
        `Eliminar interacción: ${sourceComponent.selected_component.name} -> ${
          modelComponents.find((c) => c.component_id === toRemove[0])
            .selected_component.name
        }`
      );
    }
  };

  const onOriginSelected = (component) => {
    setSourceComponent(component);
  };

  const onUpdateLabels = (inter) => {
    setInteraction(inter);
    setCreateDialog(true);

    const sourceName = inter?.source?.selected_component?.name ?? "Desconocido";
    const targetName = inter?.target?.selected_component?.name ?? "Desconocido";

    setCreateOrEditLabel(`Editar interacción: ${sourceName} -> ${targetName}`);
  };

  const openSyncComponents = () => {
    setSyncComponentsDialog(true);
  };

  const hideSyncComponentsDialog = () => {
    setSyncComponentsDialog(false);
  };

  const syncComponents = (files, download) => {
    const file = files["0"];
    if (!file) {
      showMessage("info", "Archivo no seleccionado");
      return;
    }

    const extension = file.name.split(".").at(-1);
    if (!extension) {
      showMessage("error", "Archivo no tiene extensión: " + file.name);
      return;
    }
    let filename = `ds-interactions.${extension}`;

    setSyncComponentsDialog(false);

    showMessage("info", "Procesando...", "Espere al mensaje de confirmación");
    dispatch(syncInteractions(file, filename, download)).then((error) => {
      if (error) {
        showMessage("error", "Error al sincronizar", error);
      } else {
        showMessage("success", "Sincronización completada");
        loadModelComponents();
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="p-grid list-demo">
        <div className="p-col-12 p-lg-3">
          <div className="card ">
            <Toast ref={toast} />

            <div className="p-field p-fluid">
              <h5>Origen</h5>

              <Dropdown
                value={sourceComponent}
                onChange={(e) => onOriginSelected(e.value)}
                options={modelComponents}
                optionLabel="selected_component.name"
                placeholder="Seleccionar origen"
              />

              <h5>Destinos</h5>
              <ListBox
                value={
                  interactionsGraphRep &&
                  interactionsGraphRep[sourceComponent?.component_id]
                    ? interactionsGraphRep[sourceComponent?.component_id].map(
                        (c) => c?.component_id
                      )
                    : []
                } // The source component comes from the previous selection,
                multiple={true}
                onChange={(c) => {
                  onInteractionSelected(c.value.sort());
                }}
                options={modelComponents}
                optionLabel="selected_component.name"
                optionValue="component_id"
              />

              <Button
                label="Sincronizar"
                icon="pi pi-refresh"
                className="p-button-success p-mt-4"
                onClick={openSyncComponents}
              />
            </div>

            <DeleteObjectDialog
              title={deleteDialogTitle}
              setDeleteDialog={setDeleteDialog}
              deleteDialog={deleteDialog}
              deleteConcept={deleteInteraction}
            />

            <CreateObjectDialog
              createDialog={createDialog}
              setCreateDialog={setCreateDialog}
              targetObject={interaction}
              submitted={submitted}
              createTargetObject={createInteraction}
              onInputChange={onInputChange}
              setSubmitted={setSubmitted}
              title={createOrEditLabel}
            />

            <FilePickerDialog
              visible={openSyncComponentsDialog}
              onHideDialog={hideSyncComponentsDialog}
              onContinue={syncComponents}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              checkboxLabel="¿Descargar salida?"
            />
          </div>
        </div>

        <div className="p-col-12 p-lg-9">
          <div className="card p-formgrid ">
            <h5>Interacciones</h5>
            <ScrollPanel style={{ width: "100%", height: "700px" }}>
              <ComponentsRelationshipsTable
                data={interactions}
                removeComponent={onDeleteComponent}
                setError={setError}
                onUpdateAttributes={onUpdateInteraction}
                onUpdateLabels={onUpdateLabels}
              />

              <ScrollTop
                target="parent"
                className="custom-scrolltop"
                threshold={100}
                icon="pi pi-arrow-up"
              />
            </ScrollPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Structure;
