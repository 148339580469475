import * as actions from "../actions/user-preferences.actions";

const { handleActions } = require("redux-actions");

const initialState = {
  ui: {
    color_scheme: "dark",
    menu_type: "horizontal",
    ripple_effect: false,
    input_background: "filled",
    theme_color: "purple",
    sidebar_static: false,
    user_id: null,
  },
  unitTime: {
    label: "days",
  },
};

export const UserPreferencesReducer = handleActions(
  {
    [actions.setUnitTimeAction]: (state, { payload }) => ({
      ...state,
      unitTime: payload,
    }),
    [actions.setUIAction]: (state, { payload }) => ({
      ...state,
      ui: payload,
    }),
    [actions.setAttributeUIAction]: (state, { payload }) => ({
      ...state,
      ui: {
        ...state.ui,
        ...payload,
      },
    }),
    [actions.resetUserPreferencesAction]: () => ({}),
  },
  initialState
);
